import React, { useState } from 'react';
import Layout from '../../components/Layout';
import { Paginacao } from '../../components/Paginacao';
import { GetAposentadosFiltro } from '../../components/api';
import { FaHistory } from 'react-icons/fa';
import { Mascara } from '../../util/mascara';
import InputMask from 'react-input-mask';
import { Alert } from '../../util/alertas';
import { Col } from 'reactstrap';

function Buscar() {
    const [palavraChave, setPalavraChave] = useState('');
    const [dtDiarioInicio, setDtDiarioInicio] = useState('');
    const [dtDiarioFIm, setDtDiarioFIm] = useState('');

    const [Aposentados, setAposentados] = useState([]);
    const [aposentadosFiltro, setAposentadosFiltro] = useState([]);

    const [dataPaginacao, setDataPaginacao] = useState([]);
    const [qtdPorPagina, setQtdPorPagina] = useState(5);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [offset, setOffset] = useState(5);

    const CalcularPagina = (data) => {
        const indexOfLastClient = 1 * qtdPorPagina;
        const indexOfFirstClient = indexOfLastClient - qtdPorPagina;
        const currentClients = data.slice(indexOfFirstClient, indexOfLastClient);
        setDataPaginacao(currentClients);
    }

    const BuscarAposentados = (e) => {
        if (palavraChave === '') {
            Alert('Informe a palavra chave.', false, true);
            return;
        }

        GetAposentadosFiltro(`Texto=${palavraChave}&DtDiarioInicio=${setDtDiarioInicio}&DtDiarioFIm=${setDtDiarioFIm}`, res => {
            setAposentados(res.data);
            setAposentadosFiltro(res.data);
            CalcularPagina(res.data);
        });
    }

    const SubFiltro = (e) => {
        const value = e.target.value;
        var data = Aposentados.filter(x => x.paragrafo.toUpperCase().includes(value.toUpperCase()));
        setAposentadosFiltro(data)
        CalcularPagina(data);
    }

    return (
        <Layout tela={'Buscar Aposentados'}>
            <small style={{ color: 'red' }}>Campos com * são obrigatórios</small>
            <form onSubmit={e => { e.preventDefault(); BuscarAposentados(); }}>
                <div className="row">
                    <div className="col-md-4">
                        <label>Palavra Chave<strong>*</strong>:</label>
                        <input minLength={10} required value={palavraChave} onChange={e => setPalavraChave(e.target.value)} className='form-control' type="text" name="inicio" id="inicio" />
                    </div>
                    <div className="col-md-2">
                        <label>Data Jornal de:</label>
                        <input value={dtDiarioInicio} onChange={e => setDtDiarioInicio(e.target.value)} className='form-control' type="date" name="inicio" id="inicio" />
                    </div>

                    <div className="col-md-2">
                        <label>Até:</label>
                        <input value={dtDiarioFIm} onChange={e => setDtDiarioFIm(e.target.value)} className='form-control' type="date" name="fim" id="fim" />
                    </div>
                    <div className="col-md-2" style={{ marginTop: '2rem' }}>
                        <button type='submit' className='btn btn-primary'>Buscar Diário</button>
                    </div>
                </div>
            </form>
            <br />
            <hr />
            {Aposentados.length > 0 && <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItens: 'center' }}>
                    <div className="col-md-3">
                        <label htmlFor="">Sub. Palavra Chave</label>
                        <input onChange={SubFiltro} type="text" className="form-control" />
                    </div>
                </div>
                <table className='table table-striped' style={{ marginTop: 50 }}>
                    <thead>
                        <th>#</th>
                        <th>Data Do Jornal</th>
                        <th>Paragráfo</th>
                    </thead>
                    <tbody>
                        {dataPaginacao.map((hist, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{Mascara.data(hist.dataJornal)}</td>
                                <td>{hist.paragrafo}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Paginacao
                    data={aposentadosFiltro}
                    setQtdPorPagina={setQtdPorPagina}
                    setData={setDataPaginacao}
                    limit={qtdPorPagina}
                    setLimit={setQtdPorPagina}
                    total={aposentadosFiltro.length}
                    offset={offset}
                    setOffset={setOffset}
                    current={paginaAtual}
                    setCurrentPage={setPaginaAtual}
                />
            </>}
        </Layout>
    );
}

export default Buscar;