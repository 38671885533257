import React, { Component } from 'react';
import { Route } from 'react-router';
import './custom.css'
import { Rotas } from './router/rotas';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <>
        {Rotas.map(rota => (
          <Route exact path={rota.path} component={rota.component} />
        ))}
      </>
    );
  }
}
