import React,{ useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { PostNovoDiario } from '../api';
import { Alert } from '../../util/alertas';

function ModalNovoDiario({ show, setShow, BuscarDiarios }) {

    const getAnos = () => {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let year = currentYear; year >= 2010; year--) {
            years.push(year);
        }
        return years;
    }

    const [mes, setMes] = useState("-1");
    const [anos, setAnos] = useState(getAnos());
    const [ano, setAno] = useState("-1");

    const onSubmit = () => {
        if (mes === "-1") {
            Alert("Selecione um mês", false, true);
            return;
        }
        if (ano === "-1") {
            Alert("Selecione um ano", false, true);
            return;
        }

        PostNovoDiario({
            mes, ano
        }, res => {
            BuscarDiarios();
            setShow(false);
        }, err => { })
    }

    return (
        <Modal isOpen={show}>
            <ModalHeader>
                Extrair Novo Diário/Jornal
            </ModalHeader>
            <ModalBody>
                <label>Selecione o mês:</label>
                <select className='form-control' value={mes} onChange={e => setMes(e.target.value)} name="" id="">
                    <option value="-1">Selecione</option>
                    <option value="01">Janeiro</option>
                    <option value="02">Fevereiro</option>
                    <option value="03">Março</option>
                    <option value="04">Abril</option>
                    <option value="05">Maio</option>
                    <option value="06">Junho</option>
                    <option value="07">Julho</option>
                    <option value="08">Agosto</option>
                    <option value="09">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro"</option>
                </select>
                <label>Selecione o ano:</label>
                <select className='form-control' value={ano} onChange={e => setAno(e.target.value)} name="" id="">
                    <option value="-1">Selecione</option>
                    {anos.map(x => (<option value={x}>{x}</option>))}
                </select>
            </ModalBody>
            <ModalFooter>
                <button onClick={e => setShow(false)} className='btn btn-danger'>Cancelar</button>
                <button type='button' onClick={onSubmit} className='btn btn-primary'>Adicionar</button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalNovoDiario;