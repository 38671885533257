import React, { useEffect, useState, useCallback } from 'react';
import Layout from '../../components/Layout';
import ModalNovoDiario from '../../components/modal/NovoDiario';
import { Paginacao } from '../../components/Paginacao';
import { GetAllDiarios } from '../../components/api'
import { FaDownload } from 'react-icons/fa';
import { Alert } from '../../util/alertas';
import { Mascara } from '../../util/mascara';

function Extrair() {
    const [showModal, setShowModal] = useState(false);
    const [historico, setHistorico] = useState([]);
    const [inicio, setInicio] = useState('');
    const [fim, setFim] = useState('');

    /*PAGINAÇÃO*/
    const [dataPaginacao, setDataPaginacao] = useState([]);
    const [qtdPorPagina, setQtdPorPagina] = useState(5);
    const [paginaAtual, setPaginaAtual] = useState(1);

    const CalcularPagina = (data) => {
        const indexOfLastClient = 1 * qtdPorPagina;
        const indexOfFirstClient = indexOfLastClient - qtdPorPagina;
        const currentClients = data.slice(indexOfFirstClient, indexOfLastClient);
        setDataPaginacao(currentClients);
    }

    // useEffect(() => { GetAllDiarios({ inicio, fim }, res => { setHistorico(res.data); CalcularPagina(res.data); }); }, [dataPaginacao, historico]);

    const DownloadDiario = async (diario) => {
        Alert('Download está em manutenção no momento.', true, true);
        // Download('DownloadDiarios', `diario=${diario}`, diario.replace('.pdf', '.csv'));
    };

    return (
        <Layout tela={'Diários/Jornais'}>
            <ModalNovoDiario show={showModal} setShow={setShowModal} BuscarDiarios={() => GetAllDiarios({ inicio, fim }, res => {
                setHistorico(res.data);
            })} />
            <div className="row">
                <div className="col-md-4">
                    <label>Data de:</label>
                    <input value={inicio} onChange={e => setInicio(e.target.value)} className='form-control' type="date" name="inicio" id="inicio" />
                </div>
                <div className="col-md-4">
                    <label>Data de:</label>
                    <input value={fim} onChange={e => setFim(e.target.value)} className='form-control' type="date" name="fim" id="fim" />
                </div>
                <div className="col-md-2" style={{ marginTop: '2rem' }}>
                    <button type='button' onClick={() => GetAllDiarios({ inicio, fim }, res => {
                        setHistorico(res.data);
                    })} className='btn btn-primary'>Buscar Diário</button>
                </div>

                <div className="col-md-2" style={{ marginTop: '2rem' }}>
                    <button onClick={e => setShowModal(true)} className='btn btn-primary'>Extrair Novo Diário</button>
                </div>
            </div>
            <table className='table table-striped' style={{ marginTop: 50 }}>
                <thead>
                    <th>#</th>
                    <th>Nome Diário</th>
                    <th>Quantidade de registros</th>
                    <th>Baixar</th>
                </thead>
                <tbody>
                    {dataPaginacao.map((hist, index) => (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{Mascara.data(hist.diario)}</td>
                            <td>{hist.quantidade}</td>
                            <td><FaDownload onClick={e => DownloadDiario(hist.diario)} color='448ed3' style={{ cursor: 'pointer' }} size={25} /></td>
                        </tr>
                    ))}
                    {historico.length === 0 && <tr>
                        <td colSpan={4}>Nenhum dado encontrado...</td>
                    </tr>}
                </tbody>
            </table>
            <Paginacao
                data={historico}
                setQtdPorPagina={setQtdPorPagina}
                setData={setDataPaginacao}
                limit={qtdPorPagina}
                setLimit={setQtdPorPagina}
                total={historico.length}
                current={paginaAtual}
                setCurrentPage={setPaginaAtual}
            />
        </Layout>
    );
}

export default Extrair;