// import axios from "axios"
import { net } from './net/net';
import { Alert } from '../util/alertas';

export const PostNovoDiario = (form, res, err) => {
    net.post('api/Diario/NovoDiario', form, response => res(response), erro => {
        Alert((erro.response && erro.response.data || "Houve um erro na solicitação."), false)
    });
}

export const GetAllDiarios = (form, res, err) => {
    net.get(`api/Diario/AllDiarios?inicio=${form.inicio}&fim=${form.fim}`, response => res(response), erro => {
        Alert((erro.response && erro.response.data || "Houve um erro na solicitação."), false)
    });
}

export const GetAposentadosFiltro = (form, res, err) => {
    net.get(`/Filtro?${form}`, response => res(response), erro => {
        Alert((erro.response && erro.response.data || "Houve um erro na solicitação."), false)
    });
}

export const GetHistoricoAposetado = (cpf, res, err) => {
    net.get(`/Historico?Cpf=${cpf}`, response => res(response), erro => {
        Alert((erro.response && erro.response.data || "Houve um erro na solicitação."), false)
    });
}

export const LoginUsuario = (usuario, senha) => {
    net.get(`/LoginUsuario?usuario=${usuario}&senha=${senha}`, res => {
        window.localStorage.setItem("access_token", res.data);
        window.location.href = '/';
    }, erro => {
        Alert((erro.response && erro.response.data || "Houve um erro na solicitação."), false)
    })
}

export const ValidaToken = () => {
    net.get(`/ValidaToken`)
}

export const Download = (edpoint, param, fileName) => {
    try {
        window.open(`${net.urlBase}/api/Diario/${edpoint}?${param}`)
        // net.get(`api/Diario/${edpoint}?${param}`, response => {
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', fileName);
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // }, erro => {
        //     Alert((erro.response && erro.response.data || "Houve um erro na solicitação."), false)
        // })
    } catch (error) {
        console.error('Erro ao baixar o arquivo:', error);
    }
} 