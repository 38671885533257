export const Mascara = {
    cpf: function cpf(v) {
        v = v.replace(/\D/g, "")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        return v
    },
    masp: function cpf(v) {
        v = v.replace(/\D/g, "")
        v = v.replace(/(\d{7})(\d{1,2})$/, "$1-$2")
        return v
    }, data: function data(dateString) {
        const date = new Date(dateString);

        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };

        const formattedDate = date.toLocaleString('pt-BR', options);

        return formattedDate.replace(/,/, '');
    },
}