import React, { useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import Layout from '../../components/Layout';
import { ValidaToken } from '../../components/api';

function Home() {

    useEffect(() => {
        ValidaToken();
    }, [])

    return (
        <Layout tela={'Histórico'}>
            <div className="">
                <h1>Seja Bem-Vindo(a)</h1>
                <h3>Acesse o menu para navergar <FaArrowLeft style={{ transform: 'rotate(40deg)' }} /></h3>
            </div>
        </Layout>
    );
}

export default Home;