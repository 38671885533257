import React, { useState } from 'react';
import { FaUserTie } from "react-icons/fa6";
import './login.css';
import Logo from '../../assets/Logo.png';
import { LoginUsuario } from '../../components/api';

function Login() {
    const [usuario, setUsuario] = useState('');
    const [senha, setDenha] = useState('');
    const [showSenha, setShowSenha] = useState(false);

    const handdleLogin = (event) => {
        event.preventDefault();
        LoginUsuario(usuario,senha);
    }

    return (
        <div className="backgroud-login">
            <div className="login-page">
                <div className="left-container-login">
                    <div className="container-logo-login">
                        <img src={Logo} width={350} alt="logo" />
                    </div>
                    <div style={{ width: '100%' }}>
                        <form className="login-form" onSubmit={handdleLogin}>
                            <div className="input-email">
                                <input className='form-control' required type="text" placeholder="Usuário" value={usuario} onChange={e => setUsuario(e.target.value.toUpperCase())} />
                            </div>
                            <br />
                            <div className="input-email">
                                <input className='form-control' required type={showSenha ? "text" : "password"} placeholder="Senha" value={senha} onChange={e => setDenha(e.target.value)} />
                            </div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 10 }}>
                                <input value={showSenha} onChange={e => setShowSenha(!showSenha)} type='checkbox' />
                                <span style={{ color: '#000' }} for="password">Visualizar Senha</span>
                            </div>
                            <br />
                            <button style={{ width: '100%' }} className='btn btn-primary' type="submit">ENTRAR</button>
                        </form>
                    </div>
                </div>
                <div className="right-container-login">
                    <div>
                        <h1>Bem-vindo!</h1><br />
                        <h1>Unidos, conquistamos mais</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;