// import React, { useState } from 'react';
// import { Container } from 'reactstrap';
// import { IoIosMenu } from "react-icons/io";
// import { IoMdCloseCircle } from "react-icons/io";
// import { LuFileSearch } from "react-icons/lu";
// import { FaDownload, FaCubes } from "react-icons/fa";

import React, { useState, useContext, useEffect } from 'react';
// import { AuthContext } from '../../context/AuthContext';
// import './layout.css';
import Logo from '../assets/Logo.png';
import { IoMenu } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import { IoMdExit } from "react-icons/io";
import { Size } from '../util/size';
import { Rotas } from '../router/rotas';
import { FaCubes } from 'react-icons/fa';

function Layout({ children, tela }) {
    const [toggle, setToggle] = useState(false);
    const [user, setUser] = useState({ sigla: '', nome: '' });
    const rota_atual = window.location.pathname

    const pagina_atual = Rotas.find(x => x.path === rota_atual);

    const toggleMenu = () => {
        document.querySelector('.container-nav').classList.toggle('open-nav')
    }

    const handdleLogout = () => {
        window.localStorage.clear();
        window.location.href = '/login'
    }

    useEffect(() => {
        var token = window.localStorage.getItem("access_token");
        if (!token) window.location.href = '/login';

        var obj = JSON.parse(atob(token.split('.')[1]))
    
        setUser({
            sigla: obj.unique_name.split(' ').map(nome => nome[0]).join(''),
            nome: obj.unique_name
        })

    }, [])

    return (
        <section>
            <header className="header-container">
                <button className='btn-menu' onClick={toggleMenu}><IoMenu size={Size.IconeMenu} /></button>
                <></>
                {/* <img style={{ width: '100px' }} src={Logo} alt="logo" /> */}
                <nav className="container-nav">
                    <div className="flex-align-end" style={{ padding: '1rem' }}>
                        <button className='btn-menu' onClick={toggleMenu}><IoMdCloseCircle size={Size.IconeMenu} /></button>
                    </div>
                    <div style={{ width: '100%' }}>
                        {Rotas.filter(x => x.publica).map((rota, i) => (
                            <div key={`${rota.path}-${i}`} className={rota_atual === rota.path ? "link link-active" : "link"}>
                                <a href={rota.path}>
                                    {rota.Icon && <rota.Icon size={Size.IconeMenu} />} {rota.nome}
                                </a>
                            </div>
                        ))}
                    </div>

                </nav>
                <div className="header-tools" style={{ display: toggle ? 'flex' : 'none' }}>
                    <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', gap: 20 }}>
                        {user.nome}
                    </div>
                    <div style={{ borderBottom: '1px solid #c2c2c2', height: '1px', width: '100%' }}></div>
                    <div className='links'>
                        <a onClick={handdleLogout}>Sair <IoMdExit /></a>
                    </div>
                </div>
                <div className="header-profile" onClick={e => setToggle(!toggle)}>
                    {user.sigla}
                </div>
            </header >
            <div style={{ background: "#f2f2f2", padding: '2rem', width: '100%', minHeight: '100vh', height: "auto" }}>
                <div className='header-simples'>
                    <h5><FaCubes color='#fff' /> {pagina_atual && pagina_atual.nome}</h5>
                    <button onClick={e => window.history.back()} style={{ color: '#fff' }} className='btn btn-link'>Voltar</button>
                </div>
                <br />
                {children}
            </div>
        </section>
    );
}

export default Layout;
