// import React, { useEffect } from 'react';

// function Paginacao({ paginaAtual, qtdPorPagina, setData, data, setPaginaAtual, setQtdPorPagina }) {
//     const indexOfLastClient = paginaAtual * qtdPorPagina;
//     const indexOfFirstClient = indexOfLastClient - qtdPorPagina;
//     const totalPages = Math.ceil(data.length / qtdPorPagina);

//     useEffect(() => {
//         const currentClients = data.slice(indexOfFirstClient, indexOfLastClient);
//         setData(currentClients);
//     }, [paginaAtual, qtdPorPagina, data, indexOfFirstClient, indexOfLastClient, setData]);

//     const handlePageChange = (newPage) => {
//         setPaginaAtual(newPage);
//     };

//     return (
//         <div className="pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: 5 }}>
//             <ul className='pagination'>
//                 {paginaAtual > 5 && <li>
//                     <button className='btn btn-success' onClick={() => onPageChange(1)}>...</button>
//                 </li>}
//                 {data.from({ length: Math.min(qtdPorPagina, totalPages) })
//                     .map((_, i) => i + first)
//                     .map((page) => {
//                         if (page <= totalPages)
//                             return (
//                                 <li key={page}>
//                                     <button
//                                         onClick={() => handlePageChange(page)}
//                                         className={
//                                             page === paginaAtual
//                                                 ? 'btn btn-success pagination__item--active'
//                                                 : 'btn btn-success'
//                                         }
//                                     >
//                                         {page}
//                                     </button>
//                                 </li>
//                             )
//                     })}
//                 {paginaAtual < totalPages && <li>
//                     <button className='btn btn-success' onClick={() => handlePageChange(totalPages)}>...</button>
//                 </li>}
//             </ul>
//             <select value={qtdPorPagina} onChange={e => setQtdPorPagina(e.target.value)}>
//                 <option value={5}>5</option>
//                 <option value={10}>10</option>
//                 <option value={20}>20</option>
//                 <option value={50}>50</option>
//                 <option value={100}>100</option>
//             </select>
//         </div>
//     );
// }

// export default Paginacao;


import React, { useEffect, useState } from 'react';
// import './paginacao.css';

export const Paginacao = ({
    data,limit, setLimit, total, current = 1, onChange = () => { }, setCurrentPage = () => { }, setData = () => { }
}) => {
    const MAX_ITEMS = 9;
    const MAX_LEFT = (MAX_ITEMS - 1) / 2;

    const pages = Math.ceil(total / limit);
    const first = Math.max(current - MAX_LEFT, 1);

    function onPageChange(page) {
        const indexOfLastClient = page * limit;
        const indexOfFirstClient = indexOfLastClient - limit;
        const currentClients = data.slice(indexOfFirstClient, indexOfLastClient);
        setCurrentPage(page)
        setData(currentClients);
    }

    useEffect(() => {
        onPageChange(1);
    }, [limit])

    return (
        <div>
            <br />
            <ul className='pagination'>
                {current > 5 && <li>
                    <button className='btn btn-success' onClick={() => onPageChange(1)}>...</button>
                </li>}
                {Array.from({ length: Math.min(MAX_ITEMS, pages) })
                    .map((_, i) => i + first)
                    .map((page) => {
                        if (page <= pages)
                            return (
                                <li key={page}>
                                    <button
                                        onClick={() => onPageChange(page)}
                                        className={
                                            page === current
                                                ? 'btn btn-success pagination__item--active'
                                                : 'btn btn-success'
                                        }
                                    >
                                        {page}
                                    </button>
                                </li>
                            )
                    })}
                {current < pages && <li>
                    <button className='btn btn-success' onClick={() => onPageChange(pages)}>...</button>
                </li>}
            </ul>
            <br />
            <div className="pagination">
                <span>Quantidade de registros por página:</span>
                <select value={limit} onChange={e => { setLimit(Number(e.target.value)); onChange() }}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
        </div>
    );
};
