import { FaDatabase , FaHome } from "react-icons/fa";
import { LuFileSearch } from "react-icons/lu";

import Login from '../views/Login/login';
import Extrair from '../views/Extrair/extrair';
import Buscar from '../views/Buscar/buscar';
import Home from "../views/Home/home";

export const Rotas = [ /*PUBLICA PARA APARECER NO MENU*/
    { Icon: FaHome, nome: 'Home', path: '/', component: Home, publica: true },
    { Icon: LuFileSearch, nome: 'Buscar Cliente', path: '/Busca', component: Buscar, publica: true },
    { Icon: FaDatabase , nome: 'Banco De Dados', path: '/Diarios', component: Extrair, publica: true },
    { Icon: null, nome: 'Login', path: '/Login', component: Login, publica: false },
]